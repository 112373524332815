import { earlyAccessMenu } from './early-access-menu';
import { FilterableMenu } from './model';
import { ordersMenu } from './orders-menu';
import { productsMenu } from './products-menu';
import { projectsMenu } from './project-menu';
import { reportingMenu } from './reporting-menu';
import { sandboxMenu } from './sandbox-menu';
import { segmentsMenu } from './segments-menu';
import { settingsMenu } from './settings-menu';

export const appMenu: FilterableMenu = {
  title: 'CPQ',
  titleIcon: 'trending_up',
  navigation: [
    projectsMenu,
    ordersMenu,
    segmentsMenu,
    reportingMenu,
    productsMenu,
    sandboxMenu,
    settingsMenu,
    earlyAccessMenu,
  ],
};
