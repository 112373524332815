import { configurationMenu } from './configuration-menu';
import { FilterableMenu } from './model';

export const solutionMenu: FilterableMenu = {
  title: '', // dynamic created
  navigation: [
    configurationMenu,
    {
      title: 'common.calculation',
      icon: 'calculate',
      routerLink: '/calculation',
    },
    {
      title: 'offer.offer',
      icon: 'local_offer',
      routerLink: '/offer',
    },
    {
      title: 'checkout.checkout',
      icon: 'send',
      routerLink: '/checkout',
    },
  ],
};
