<ng-container gaCategoryGroup="App" gaLabelGroup="HeaderAndOverlay">
  <ng-container *ngIf="userService?.isAuthorized">
    <ds-spotlight id="cpq-spotlight-welcome-2023-03-01">
      <h1>
        {{ 'spotlight.release_2023_03_01.welcome.headline' | translate }}
      </h1>
      <span>
        {{ 'spotlight.release_2023_03_01.welcome.message' | translate }}
      </span>
      <ul>
        <li>
          {{
            'spotlight.release_2023_03_01.welcome.items.copy_solution_into_existing_project'
              | translate
          }}
        </li>
      </ul>
    </ds-spotlight>

    <div
      class="help-spotlight-helper spotlight-helper"
      [spotlight]="helpPageSpotlight"
    ></div>
    <ds-spotlight
      id="cpq-spotlight-price_amendment"
      #helpPageSpotlight
      position="above"
    >
      {{ 'spotlight.release_2021_04_01.price_amendment' | translate }}
    </ds-spotlight>

    <div
      class="feedback-spotlight-helper spotlight-helper"
      [spotlight]="feedbackSpotlight"
    ></div>
    <ds-spotlight id="cpq-spotlight-feedback" #feedbackSpotlight>
      {{ 'spotlight.release_2021_03_01.fast_feedback' | translate }}
    </ds-spotlight>

    <div
      class="sidebar-spotlight-helper spotlight-helper"
      [spotlight]="sidebarSpotlight"
    ></div>
    <ds-spotlight id="cpq-spotlight-2022-12-sidebar" #sidebarSpotlight>
      {{ 'spotlight.release_2022_12_01.sidebar' | translate }}
    </ds-spotlight>
  </ng-container>

  @if (offerPage) {
    <div fxFlex="1 1 auto" class="flex-page-container" #routerContainer>
      <sl-shared-router-container #slRouterContainer>
        <router-outlet (activate)="slRouterContainer.animate()"></router-outlet>
      </sl-shared-router-container>
    </div>
  } @else {
    <ds-app-wrapper [menu]="cartMenu ?? menu" disablePageHeadlines>
      <ds-breadcrumbs
        fxFlex="0 0 auto"
        baseUrl="/"
        baseLabel="CPQ"
        [baseSupressTitleCase]="true"
        class="print-hidden"
        gaClickListener="BreadcrumbsClick"
      >
      </ds-breadcrumbs>
      <div fxFlex="1 1 auto" class="flex-page-container" #routerContainer>
        <sl-shared-router-container #slRouterContainer>
          <router-outlet
            (activate)="slRouterContainer.animate()"
          ></router-outlet>
        </sl-shared-router-container>
      </div>
    </ds-app-wrapper>
  }
  <div class="overlay" *ngIf="showLoadingSpinner$ | async">
    <div class="overlay-background cdk-overlay-dark-backdrop"></div>
    <mat-progress-spinner
      *pdLet="loadingSpinnerValue$ | async as spinnerValue"
      color="primary"
      [mode]="
        spinnerValue && spinnerValue !== 100 ? 'determinate' : 'indeterminate'
      "
      [strokeWidth]="5"
      [diameter]="50"
      [value]="spinnerValue"
      gaClickListener="LoadingClick"
    ></mat-progress-spinner>
  </div>
</ng-container>
