import { UserService } from '@features/auth';
import { CPQ_ROLES } from '../roles';
import { FilterableMenuItem } from './model';

export const earlyAccessMenu: FilterableMenuItem = {
  title: 'breadcrumb.early_access',
  icon: 'flag',
  routerLink: '/early-access',
  isHighlighted: true,
  data: { id: 'earlyAccess' },
  filterPredicate: (userService: UserService) =>
    userService?.hasOneRole([
      CPQ_ROLES.CPQ_USER,
      CPQ_ROLES.CPQ_SALES_CONSULTANT,
    ]),
};
