import { UserService } from '@features/auth';
import { CPQ_ROLES } from '../roles';
import { FilterableMenuItem } from './model';
import { cpqSalesConsultant } from './shared';

const cpqOrHistoryUser = (userService: UserService) =>
  userService.hasOneRole([CPQ_ROLES.CPQ_USER, CPQ_ROLES.CPQ_ORDER_HISTORY]);

export const currentOrdersMenuItem: FilterableMenuItem = {
  title: 'orders.current_orders',
  icon: 'list',
  routerLink: '/orders',
  data: { id: 'currentOrders' },
  filterPredicate: (userService: UserService) =>
    userService.hasRole(CPQ_ROLES.CPQ_USER),
};

export const orderHistoryMenuItem: FilterableMenuItem = {
  title: 'orders.order_history',
  icon: 'history',
  routerLink: '/orders/history',
  data: { id: 'orderHistory' },
  filterPredicate: (userService: UserService) =>
    !cpqSalesConsultant(userService) && cpqOrHistoryUser(userService),
};

export const ordersFailedToSendMenuItem: FilterableMenuItem = {
  title: 'orders.failed_to_send',
  icon: 'assignment_late',
  routerLink: '/orders/failed-to-send',
  data: { id: 'ordersFailedToSend' },
  filterPredicate: (userService: UserService) =>
    userService.hasRole(CPQ_ROLES.CPQ_INTERNAL_SALES),
};

export const ordersMenu: FilterableMenuItem = {
  title: 'project.orders',
  icon: 'assignment_turned_in',
  routerLink: '/orders',
  data: { id: 'order' },
  filterPredicate: (userService: UserService) =>
    !cpqSalesConsultant(userService) && cpqOrHistoryUser(userService),
  children: [
    currentOrdersMenuItem,
    orderHistoryMenuItem,
    ordersFailedToSendMenuItem,
  ],
};
