import { UserService } from '@features/auth';
import { CPQ_ROLES } from '../roles';
import { FilterableMenuItem } from './model';

export const segmentsMenu: FilterableMenuItem = {
  title: 'common.segments',
  icon: 'nature_people',
  routerLink: '/industries',
  filterPredicate: (userService: UserService) =>
    userService?.hasOneRole([
      CPQ_ROLES.CPQ_USER,
      CPQ_ROLES.CPQ_SALES_CONSULTANT,
    ]),
};
