import { UserService } from '@features/auth';
import { CPQ_ROLES } from '../roles';
import { FilterableMenuItem } from './model';

export const sandboxMenu: FilterableMenuItem = {
  title: 'common.sandbox',
  icon: 'build',
  routerLink: '/sandbox',
  filterPredicate: (userService: UserService) =>
    userService?.hasOneRole([
      CPQ_ROLES.CPQ_USER,
      CPQ_ROLES.CPQ_SALES_CONSULTANT,
    ]),
};
